import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import Loader from './Loader'
import { useCurrentOrganization } from '../data/organization'
import { ISubmission, ISubmissionFilters } from '../interfaces/ISubmission'
import { useUser } from '../data/user'
import MainRoadmapButtons from './MainRoadmapButtons'
import DedicatedRoadmapField from './DedicatedRoadmapField'
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@alissavrk/dnd-kit-core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import PopupWrapper from './PopupWrapper'
import MainPostView from './MainPostView'
import { useSingleSubmission } from '@/data/submission'
import { cn, generateDefaultBaseFilterForRoadmap, generateRoadmapTemplateItems } from '@/lib/utils'
import useElementSize from './useElementSize'
import ActiveFilterTab from './ActiveFilterTab'
import { useAtom } from 'jotai'
import { activeRoadmapAtom, submissionActiveFilterURIAtom } from '@/atoms/submissionAtom'
import FilterSyncer from './FilterSyncer'
import useSubmissionUrl from '@/hooks/submissionUrlSyncer'
import toast from 'react-hot-toast'
import MainFilterDropdown from './MainFilterDropdown'
import PopularitySorter from './PopularitySorter'
import { isMember } from '@/lib/acl'
import { Button } from './radix/Button'
import { ArrowLeftIcon, ArrowRightIcon, CogIcon } from '@heroicons/react/solid'
import { ContentModifier } from './ContentReplacer'
import { roadmapRemutationQueueAtom } from '@/atoms/roadmapAtom'
import { v4 as uuid } from 'uuid'
import Link from 'next/link'
import { motion, AnimatePresence } from 'framer-motion'

const MainRoadmapView: React.FC<{
  isDashboard?: boolean
  enableRedirection?: boolean
  widget?: boolean
  initialActiveExtraBaseFilters?: ISubmissionFilters
}> = ({ isDashboard = false, enableRedirection, widget, initialActiveExtraBaseFilters }) => {
  const { org } = useCurrentOrganization()
  const { user } = useUser()

  const categoryIds = org?.structure?.roadmap?.hiddenCategories?.map(
    (cat) => org?.postCategories?.find((c) => c.category === cat)?.id || ''
  )

  const [activeRoadmapAtomValue, setActiveRoadmapAtom] = useAtom(activeRoadmapAtom)
  const activeRoadmap = activeRoadmapAtomValue || org?.roadmaps?.[0]

  const [isScrolledToStart, setIsScrolledToStart] = useState(true)
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false)
  const [isRefAvailable, setIsRefAvailable] = useState(false)

  const prevIsScrolledToStart = useRef(true)
  const prevIsScrolledToEnd = useRef(false)

  const columnWrapperRef = useRef<HTMLDivElement>(null)

  // Set the ref availability when the component mounts
  useEffect(() => {
    if (columnWrapperRef.current && !isRefAvailable) {
      setIsRefAvailable(true)
    }
  }, [columnWrapperRef.current, isRefAvailable])

  // Update the active roadmap atom value
  useEffect(() => {
    if (!activeRoadmapAtomValue) {
      setActiveRoadmapAtom(org?.roadmaps?.[0])
    }
  }, [activeRoadmapAtomValue])

  const defaultBaseFilters = useMemo(() => {
    return generateDefaultBaseFilterForRoadmap(activeRoadmap?.baseFilter || '')
  }, [activeRoadmap?.baseFilter])

  const [activeExtraBaseFilters, setActiveExtraBaseFilters] = useState<ISubmissionFilters>(
    initialActiveExtraBaseFilters || {
      sortBy: defaultBaseFilters.sortBy,
      advancedFilters: [],
      inReview: undefined,
      includePinned: true,
      limit: 10,
    }
  )

  const [activeFilterURI, setActiveFilterURI] = useAtom(submissionActiveFilterURIAtom)

  const [activeDraggingItemId, setActiveDraggingItemId] = useState<string | undefined>(undefined)
  const [activeDraggingItem, setActiveDraggingItem] = useState(null)
  const [mainPostView, setMainPostView] = useState(false)
  const [activeSubmissionId, setActiveSubmissionId] = useState('')
  const [remutationQueue, setRemutationQueue] = useAtom(roadmapRemutationQueueAtom)
  const [timeframeOffset, setTimeframeOffset] = useState(0)
  const [wasSingleSubmissionChanged, setWasSingleSubmissionChanged] = useState(false)
  const { restoreUrl, setUrl } = useSubmissionUrl(true)

  const {
    submission,
    mutateSingleSubmission,
    rawSubmissionData: rawSingleSubmissionData,
  } = useSingleSubmission(activeSubmissionId)

  const handleDragStart = (event: any) => {
    setActiveDraggingItemId(event.active.id)
  }

  const handleDragEnd = (event: any) => {
    setActiveDraggingItemId(undefined)
  }

  const [elementRef, { width }] = useElementSize()

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  )

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const mutatorInterceptor = (data: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        mutateSubmissionIfNeeded(data)
        updateSubmissionStatusAndLabelsIfNeeded(data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  const mutateSubmissionIfNeeded = (data: any): void => {
    if (data) {
      mutateSingleSubmission(data, false)
    } else {
      mutateSingleSubmission()
    }
  }

  const refreshAllRoadmapItems = () => {
    setRemutationQueue(
      activeRoadmap?.items?.map((item) => ({
        id: uuid(),
        roadmapColumnId: item._id,
        changeCount: 0,
        modifyResults: (submissions: ISubmission[]) => submissions,
        fullRefresh: true,
      })) || []
    )
    setWasSingleSubmissionChanged(false)
  }

  const updateSubmissionStatusAndLabelsIfNeeded = (data: any): void => {
    setWasSingleSubmissionChanged(true)
    setRemutationQueue(
      activeRoadmap?.items?.map((item) => ({
        id: uuid(),
        roadmapColumnId: item._id,
        changeCount: 0,
        modifyResults: (submissions: ISubmission[]) =>
          submissions.map((submission) =>
            submission.id === activeSubmissionId ? data?.results[0] : submission
          ),
      })) || []
    )
  }

  const generateDateBasedRoadmapItems = useCallback(() => {
    return generateRoadmapTemplateItems(activeRoadmap, timeframeOffset)
  }, [activeRoadmap, timeframeOffset])

  const generatedItems = useMemo(() => {
    if (activeRoadmap?.template?.type === 'date') {
      const items = generateDateBasedRoadmapItems()
      if (JSON.stringify(activeRoadmap?.items) !== JSON.stringify(items)) {
        setActiveRoadmapAtom({ ...activeRoadmap, items })
      }
      return items
    }
    return activeRoadmap?.items || []
  }, [activeRoadmap, generateDateBasedRoadmapItems])

  const shouldShowScrollIndicators =
    // ((generatedItems?.length && generatedItems.length > 4) || isDashboard) &&
    activeRoadmap && isMounted

  // Add this state near the other state declarations
  const [isScrollMeasured, setIsScrollMeasured] = useState(false)

  // Update the useEffect that handles scroll detection
  useEffect(() => {
    if (!isRefAvailable || !isMounted) return
    if (!shouldShowScrollIndicators) {
      setIsScrollMeasured(false)
      setIsScrolledToStart(true)
      setIsScrolledToEnd(false)
      return
    }

    const container = columnWrapperRef.current
    if (!container) return

    const handleScroll = () => {
      requestAnimationFrame(() => {
        const scrollLeft = container.scrollLeft
        const clientWidth = container.clientWidth
        const scrollWidth = container.scrollWidth

        const isAtStart = scrollLeft <= 50
        const isAtEnd = scrollLeft + clientWidth >= scrollWidth - 50

        if (prevIsScrolledToStart.current !== isAtStart) {
          prevIsScrolledToStart.current = isAtStart
          setIsScrolledToStart(isAtStart)
        }

        if (prevIsScrolledToEnd.current !== isAtEnd) {
          prevIsScrolledToEnd.current = isAtEnd
          setIsScrolledToEnd(isAtEnd)
        }

        // Set measurement complete
        setIsScrollMeasured(true)
      })
    }

    // Initial check with a longer delay
    const timeoutId = setTimeout(handleScroll, 300)

    container.addEventListener('scroll', handleScroll, { passive: true })
    const resizeObserver = new ResizeObserver(handleScroll)
    resizeObserver.observe(container)

    return () => {
      clearTimeout(timeoutId)
      container.removeEventListener('scroll', handleScroll)
      resizeObserver.disconnect()
    }
  }, [isRefAvailable, shouldShowScrollIndicators, isMounted])

  // Helper functions for scrolling
  const scrollLeft = () => {
    const container = columnWrapperRef.current
    if (container) {
      container.scrollBy({ left: -300, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    const container = columnWrapperRef.current
    if (container) {
      container.scrollBy({ left: 300, behavior: 'smooth' })
    }
  }

  // Update the conditions for showing scroll indicators
  const shouldShowRightIndicator =
    shouldShowScrollIndicators && !isScrolledToEnd && isScrollMeasured
  const shouldShowLeftIndicator =
    shouldShowScrollIndicators && !isScrolledToStart && isScrollMeasured

  const isEmptyDescription = activeRoadmap?.description === '<p></p>' || !activeRoadmap?.description

  return (
    <div ref={elementRef} style={{ minHeight: !isDashboard ? 'calc(100dvh - 161px)' : '' }}>
      <div className="mt-1">
        {/* Popup for the main post view */}
        <PopupWrapper
          fullScreen={true}
          isOpen={mainPostView}
          setIsOpen={() => {
            setMainPostView(false)
            restoreUrl()
            if (wasSingleSubmissionChanged) {
              refreshAllRoadmapItems()
            }
          }}
          large={true}
          hasPadding={false}
        >
          {activeSubmissionId !== '' && submission ? (
            <MainPostView
              roadmapView={true}
              setActiveSubmissionId={setActiveSubmissionId}
              setOpen={setMainPostView}
              rawSubmissionData={rawSingleSubmissionData}
              mutateSubmissions={mutatorInterceptor as any}
              submission={submission}
              fetchResults={mainPostView}
            />
          ) : (
            <div className="flex items-center justify-center min-h-[426px]">
              <div className="text-background-accent h-7 w-7 dark:text-background-accent">
                <Loader />
              </div>
            </div>
          )}
        </PopupWrapper>

        {/* Synchronize filters */}
        <FilterSyncer
          setFilters={setActiveExtraBaseFilters}
          filters={activeExtraBaseFilters}
          setActiveFilterURI={setActiveFilterURI}
          defaultFilters={defaultBaseFilters}
          hideCompletedAndCancelled={false}
        />

        {/* Edit roadmap button for members */}
        {isMember(user?.id, org) && (
          <div className="fixed inset-x-0 bottom-6 flex justify-center pointer-events-none z-[40]">
            <div className="relative flex items-center gap-3 pointer-events-auto">
              <Link legacyBehavior href={`/dashboard/settings/roadmap`} target="_blank">
                <button className="relative inline-flex items-center px-4 py-2 bg-white/90 dark:bg-secondary/60 rounded-full shadow-lg dashboard-secondary backdrop-blur-md hover:shadow-xl">
                  <CogIcon className="secondary-svg mr-1.5" /> Edit roadmap
                </button>
              </Link>
            </div>
          </div>
        )}

        {/* Roadmap header */}
        <div className={cn(!isDashboard && 'max-w-5xl mx-auto')}>
          <div
            className={cn(
              'grid grid-cols-1 md:grid-cols-2 w-full gap-4 mb-8 justify-between',
              isEmptyDescription ? 'items-center' : 'items-start',
              isDashboard ? 'mt-6' : !widget ? 'mt-10' : ''
            )}
          >
            {!widget ? (
              <div className="flex flex-col w-full">
                <p
                  className={cn(
                    'text-2xl font-bold text-gray-500 dark:text-white',
                    isDashboard ? 'text-xl' : ''
                  )}
                >
                  {activeRoadmap?.name}
                </p>
                {activeRoadmap?.description && (
                  <div className="mt-3">
                    <ContentModifier content={activeRoadmap?.description} />
                  </div>
                )}
              </div>
            ) : null}
            <div className={cn(isEmptyDescription ? 'sm:mt-0 mt-2' : 'mt-5', 'w-full')}>
              <div className="flex justify-end gap-3 items-start">
                <MainFilterDropdown
                  hideStatusOption={activeExtraBaseFilters.advancedFilters.some(
                    (filter) => filter.type === 'status'
                  )}
                  CustomButton={
                    !isDashboard
                      ? ({ children }) => (
                          <Button variant="outline" className="bg-secondary/40 dashboard-border">
                            {children}
                          </Button>
                        )
                      : undefined
                  }
                  big={isDashboard ? true : false}
                  publicBoard={isDashboard ? false : true}
                  setActiveFilters={setActiveExtraBaseFilters}
                  activeFilters={activeExtraBaseFilters.advancedFilters}
                />
                <PopularitySorter
                  CustomButton={
                    !isDashboard
                      ? ({ children }) => (
                          <Button variant="outline" className="bg-secondary/40 dashboard-border">
                            {children}
                          </Button>
                        )
                      : undefined
                  }
                  widget={isDashboard ? false : true}
                  filters={activeExtraBaseFilters}
                  setFilters={setActiveExtraBaseFilters}
                />
                {!isDashboard && <MainRoadmapButtons isDashboard={widget} />}
                {activeRoadmap?.template?.type === 'date' && !widget && (
                  <div className="flex items-center gap-2 border-l dashboard-border ml-0 pl-3">
                    <Button
                      variant={'outline'}
                      onClick={() => setTimeframeOffset(timeframeOffset - 1)}
                    >
                      <ArrowLeftIcon className="secondary-svg" />
                    </Button>
                    <Button
                      variant={'outline'}
                      onClick={() => setTimeframeOffset(timeframeOffset + 1)}
                    >
                      <ArrowRightIcon className="secondary-svg" />
                    </Button>
                  </div>
                )}
              </div>
              <ActiveFilterTab
                customActiveStyle={
                  'flex items-end bg-gradient-to-r from-transparent to-gray-50/60 dark:to-secondary/40 rounded-lg p-2 justify-end mt-4'
                }
                roadmap={true}
                activeFilters={activeExtraBaseFilters.advancedFilters}
                setActiveFilters={setActiveExtraBaseFilters}
                categoryIds={categoryIds}
              />
            </div>
          </div>
        </div>

        {/* Roadmap content */}
        <div
          className={cn(
            'relative overflow-hidden mb-8 mx-auto w-full',
            isDashboard && 'mt-4',
            isDashboard
              ? ''
              : generatedItems?.length > 4
              ? 'max-w-[1500px] xl:px-4'
              : generatedItems?.length > 3
              ? 'max-w-8xl xl:px-4'
              : 'max-w-5xl'
          )}
        >
          <div className="hidden md:block">
            {/* Left Scroll Indicator */}
            <AnimatePresence>
              {shouldShowLeftIndicator && (
                <motion.div
                  key="scroll-indicator-left"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.5, ease: 'easeOut' }}
                  onClick={scrollLeft}
                  className="absolute z-40 left-0 cursor-pointer backdrop-blur-[1px] inset-y-0 w-12 bg-gradient-to-l from-transparent to-background dark:to-background opacity-80 hover:opacity-60 flex items-center justify-start pl-4"
                >
                  <button className="p-2 rounded-full pointer-events-none bg-secondary/40 hover:bg-secondary/60 transition-colors">
                    <ArrowLeftIcon className="secondary-svg" />
                  </button>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Right Scroll Indicator */}
            <AnimatePresence>
              {shouldShowRightIndicator && (
                <motion.div
                  key="scroll-indicator-right"
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={{ duration: 0.5, ease: 'easeOut' }}
                  onClick={scrollRight}
                  className="absolute z-40 right-0 cursor-pointer backdrop-blur-[1px] inset-y-0 w-12 bg-gradient-to-r from-transparent to-background dark:to-background opacity-80 hover:opacity-60 flex items-center justify-end pr-2"
                >
                  <button className="p-2 rounded-full pointer-events-none bg-secondary/60 transition-colors">
                    <ArrowRightIcon className="secondary-svg" />
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {org && activeRoadmap && isMounted ? (
            <div
              ref={columnWrapperRef}
              className="md:flex pl-1.5 pb-4 items-center justify-center md:w-full custom-scrollbar overflow-x-auto"
            >
              <div className="flex-row md:inline-flex md:w-full">
                <DndContext
                  sensors={sensors}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onDragCancel={handleDragEnd}
                  modifiers={[restrictToWindowEdges]}
                >
                  {generatedItems.map((roadmap, index) => (
                    <DedicatedRoadmapField
                      enableRedirection={enableRedirection}
                      width={width}
                      key={`${roadmap._id}`}
                      activeItem={roadmap}
                      index={index}
                      setActiveDraggingItem={setActiveDraggingItem}
                      activeDraggingItemId={activeDraggingItemId}
                      setActiveSubmissionId={setActiveSubmissionId}
                      setMainPostView={(view: any) => {
                        setMainPostView(view)
                        if (activeSubmissionId) {
                          toast.dismiss(activeSubmissionId)
                        }
                      }}
                      activeSubmissionId={activeSubmissionId}
                      defaultBaseFilters={defaultBaseFilters}
                      activeExtraBaseFilters={activeExtraBaseFilters}
                      isLast={index === generatedItems.length - 1}
                      moreThanTwo={generatedItems.length > 2}
                      setUrl={setUrl}
                    />
                  ))}
                  <DragOverlay dropAnimation={null}>
                    {activeDraggingItemId && activeDraggingItem ? activeDraggingItem : null}
                  </DragOverlay>
                </DndContext>
              </div>
            </div>
          ) : (
            <div className="w-5 h-5 mx-auto mt-8 ">
              <div className="secondary-svg">
                <Loader />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainRoadmapView
