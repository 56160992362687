import React from 'react'
import { IOrganization } from '../interfaces/IOrganization'
import { useTranslation } from 'next-i18next'
import { Button } from './radix/Button'
import router, { useRouter } from 'next/router'
import { useCurrentOrganization } from '@/data/organization'
import FeaturedIcon from './docs/FeaturedIcon'
import { activeRoadmapAtom } from '@/atoms/submissionAtom'
import { useAtom } from 'jotai'
import { cn } from '@/lib/utils'
import Link from 'next/link'
import MultiselectButton from './MultiselectButton'
import ModularComboBox from './radix/ModularComboBox'
import { CalendarIcon, MapIcon } from '@heroicons/react/solid'
import { CommandGroup, CommandItem } from './radix/Command'

const MainRoadmapButtons: React.FC<{ isDashboard?: boolean }> = ({ isDashboard }) => {
  const { t } = useTranslation()

  const { org } = useCurrentOrganization()
  const [activeRoadmap, setActiveRoadmap] = useAtom(activeRoadmapAtom)

  if (org?.roadmaps?.length === 1) return null

  return (
    <div>
      <ModularComboBox
        searchableDisplayName="roadmap"
        TriggerButton={() => (
          <MultiselectButton
            icon={
              activeRoadmap?.icon ? (
                <span className="mr-1.5 flex-shrink-0 secondary-svg">
                  <FeaturedIcon small={true} icon={activeRoadmap.icon} />
                </span>
              ) : (
                <MapIcon className="mr-1.5 flex-shrink-0" />
              )
            }
            className={cn('w-52 h-full capitalize', isDashboard ? 'py-1.5' : '')}
          >
            {activeRoadmap?.name}
          </MultiselectButton>
        )}
        popoverContentProps={{
          align: 'end',
        }}
        CommandItems={({ closeComboBox }) => (
          <CommandGroup>
            {org?.roadmaps
              .filter((roadmap) => roadmap._id !== activeRoadmap?._id)
              .map((roadmap) => (
                <CommandItem
                  key={roadmap._id}
                  onSelect={() => {
                    closeComboBox()
                    setActiveRoadmap(roadmap)
                    if (!isDashboard) {
                      router.push(`/roadmap/${roadmap.slug}`, undefined, { shallow: true })
                    }
                  }}
                >
                  {roadmap.icon && (
                    <span className="mr-1.5 secondary-svg">
                      <FeaturedIcon small={true} icon={roadmap.icon} />
                    </span>
                  )}
                  {roadmap.name}
                </CommandItem>
              ))}
          </CommandGroup>
        )}
      />
    </div>
  )
}

export default MainRoadmapButtons
