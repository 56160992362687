import { ISubmission } from '@/interfaces/ISubmission'
import { atom } from 'jotai'

export const roadmapRemutationQueueAtom = atom<
  {
    id: string
    roadmapColumnId: string
    changeCount: number
    modifyResults: (submissions: ISubmission[]) => ISubmission[]
    fullRefresh?: boolean
  }[]
>([])
