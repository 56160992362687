import { useSingleSubmission, useSubmissionsWithFiltering } from '@/data/submission'
import { IRoadmapDocument } from '@/interfaces/IOrganization'
import { ISubmission, ISubmissionFilters } from '@/interfaces/ISubmission'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { statusColorData } from './Status'
import Loader from './Loader'
import { InView } from 'react-intersection-observer'
import CreatePost from './CreatePost'
import PopupWrapper from './PopupWrapper'
import { AnimatePresence, motion } from 'framer-motion'
import { useDndMonitor, useDroppable } from '@alissavrk/dnd-kit-core'
import DraggableElement from './DraggableElement'
import RoadmapElement from './RoadmapElement'
import { updateSubmissionInfo } from 'network/lib/submission'
import {
  cn,
  generateDefaultFilterForRoadmapColumn,
  generateRoadmapFilter,
  mongooseQueryStringToObject,
} from '@/lib/utils'
import EmptyIllustration from './EmptyIllustration'
import { toast as sonnerToast } from 'sonner'
import { useAtom } from 'jotai'
import { fullRoadmapAtom } from '@/atoms/orgAtom'
import FeaturedIcon from './docs/FeaturedIcon'
import { Trans, useTranslation } from 'next-i18next'
import { sortBy } from './PopularitySorter'
import { can, isMember } from '@/lib/acl'
import { getColor } from './TagBullet'
import { updateFilters } from './FilterSyncer'
import { useCurrentOrganization } from '@/data/organization'
import { useUser } from '@/data/user'
import { activeRoadmapAtom } from '@/atoms/submissionAtom'
import { roadmapRemutationQueueAtom } from '@/atoms/roadmapAtom'
import { v4 as uuid } from 'uuid'
import { performSubmissionMutation } from '@/lib/submissionMutator'
import { Button } from './radix/Button'
import { PlusIcon } from '@heroicons/react/solid'
import { shortenNumber } from './VotingHandler'

const DedicatedRoadmapField: React.FC<{
  setActiveHoveringItem?: any
  activeItem: IRoadmapDocument['items'][0]
  setActiveDraggingItem?: any
  activeDraggingItemId?: string
  setActiveSubmissionId?: any
  setMainPostView?: any
  width?: number
  defaultBaseFilters: ISubmissionFilters
  activeExtraBaseFilters: ISubmissionFilters
  enableRedirection?: boolean
  isLast?: boolean
  moreThanTwo?: boolean
  index?: number
  setUrl?: any
  mainPostView?: boolean
  activeSubmissionId: string
}> = ({
  activeItem,
  setActiveDraggingItem,
  activeDraggingItemId,
  setActiveSubmissionId,
  setMainPostView,
  width = 0,
  enableRedirection,
  isLast,
  moreThanTwo,
  setUrl,
  mainPostView,
  activeSubmissionId,
  defaultBaseFilters,
  activeExtraBaseFilters,
}) => {
  const { submission: singleSubmission, mutateSingleSubmission } = useSingleSubmission(
    activeSubmissionId || ''
  )
  const { org } = useCurrentOrganization()
  const { user } = useUser()
  const { t } = useTranslation()
  const [isFullRoadmap] = useAtom(fullRoadmapAtom)
  const [customModal, setCustomModal] = useState<any>(null)
  const [activeRoadmap, setActiveRoadmap] = useAtom(activeRoadmapAtom)
  const [createPost, setCreatePost] = useState(false)
  const isProcessingRef = useRef(false)
  const { isOver, setNodeRef } = useDroppable({
    id: activeItem?._id || '',
  })
  const sortByNames = {
    [sortBy.top]: t('top-upvoted'),
    [sortBy.recent]: t('recent-posts'),
    [sortBy.trending]: t('trending-posts'),
    [sortBy.inReview]: t('pending-posts'),
    [sortBy.mrr]: t('upvoter-mrr'),
  }

  const mainFilters = useMemo(
    () => mongooseQueryStringToObject(activeItem?.filter || ''),
    [activeItem?.filter]
  )

  const defaultFiltersForColumn = useMemo(
    () => updateFilters(defaultBaseFilters, mainFilters, defaultBaseFilters),
    [defaultBaseFilters, mainFilters]
  )

  const filters = useMemo(
    () => generateDefaultFilterForRoadmapColumn(defaultFiltersForColumn, activeExtraBaseFilters),
    [JSON.stringify(activeExtraBaseFilters), defaultFiltersForColumn]
  )

  const [isLoading, setIsLoading] = useState(false)

  useDndMonitor({
    onDragEnd(event) {
      if (event.active?.id && event?.over) {
        const activeRoadmapId = event.active.id?.toString()?.split('-')[0]
        if (activeRoadmapId === activeItem?._id) {
          handleStatusChange(event)
        }
      }
    },
  })

  const handleStatusChange = (event: any) => {
    const movedToRoadmapId = event.over.id?.split('-')[0]
    const movedToSubmissionId = event.active.id?.split('-')[1]
    const movedToRoadmap = activeRoadmap?.items?.find((roadmap) => roadmap._id === movedToRoadmapId)
    const movedSubmission = submissionResults?.find(
      (submission) => submission.id === movedToSubmissionId
    )

    if (!movedSubmission || !movedToRoadmap) return

    const constructedFilter = mongooseQueryStringToObject(movedToRoadmap?.filter || '')

    // Check if moved roadmap has one status filter
    const statusFilter = constructedFilter?.find(
      (filter) => filter.type === 's' && filter.values.length >= 1
    )
    const dateFilter = constructedFilter?.find(
      (filter) => filter.type === 'e' && filter.values.length >= 1
    )

    const allowedFilters = ['s', 'e', 'sortBy', 'inReview']

    const onlyStatusOrDateFilter = constructedFilter?.every((filter) =>
      allowedFilters.includes(filter.type)
    )

    if (!onlyStatusOrDateFilter || (statusFilter && dateFilter)) {
      sonnerToast.error('Dragging is only supported with status or date filtered columns')
      return
    }

    if (statusFilter) {
      const firstStatusId = statusFilter.values[0]

      const firstStatus = org?.postStatuses?.find((status) => status.id === firstStatusId)

      if (!firstStatus) return

      if (firstStatus.id === movedSubmission.postStatus?.id) {
        sonnerToast.error('Post is already in this status')
        return
      }

      const modifiedSubmission = {
        ...movedSubmission,
        postStatus: firstStatus,
      } as ISubmission

      remutationQueueWrapper([
        ...remutationQueue,
        {
          id: uuid(),
          roadmapColumnId: activeItem._id,
          changeCount: -1,
          modifyResults: (submissions: ISubmission[]) => {
            return submissions.filter((submission) => submission.id !== movedToSubmissionId)
          },
        },
        {
          id: uuid(),
          roadmapColumnId: movedToRoadmapId,
          changeCount: 1,
          modifyResults: (submissions: ISubmission[]) => {
            return [modifiedSubmission, ...submissions]
          },
        },
      ])

      sonnerToast.promise(
        updateSubmissionInfo({
          submissionId: movedToSubmissionId,
          postStatus: firstStatus,
        }),
        {
          loading: 'Updating post status...',
          success: () => {
            mutateSingleSubmission()
            return `Moved to ${movedToRoadmap?.title}, changed status to ${firstStatus?.name}`
          },
          // remutationQueueWrapper([
          //   ...remutationQueue,
          //   {
          //     id: uuid(),
          //     roadmapColumnId: movedToRoadmapId,
          //     modifyResults: (submissions: ISubmission[]) => {
          //       return submissions.filter((submission) => submission.id !== movedToSubmissionId)
          //     },
          //   },
          //   {
          //     id: uuid(),
          //     roadmapColumnId: activeItem._id,
          //     modifyResults: (submissions: ISubmission[]) => {
          //       return [movedSubmission, ...submissions]
          //     },
          //   },
          // ])
          error: () => 'Failed to update submission status',
        }
      )
    }

    if (dateFilter) {
      // _id of the roadmap item is the end date timestamp
      const newDateTimestamp = parseInt(movedToRoadmapId)

      const newDate = new Date(newDateTimestamp)

      const modifiedSubmission = {
        ...movedSubmission,
        endDate: newDate,
      } as ISubmission
      remutationQueueWrapper([
        ...remutationQueue,
        {
          id: uuid(),
          roadmapColumnId: activeItem._id,
          changeCount: -1,
          modifyResults: (submissions: ISubmission[]) => {
            return submissions.filter((submission) => submission.id !== movedToSubmissionId)
          },
        },
        {
          id: uuid(),
          roadmapColumnId: movedToRoadmapId,
          changeCount: 1,
          modifyResults: (submissions: ISubmission[]) => {
            return [modifiedSubmission, ...submissions]
          },
        },
      ])

      sonnerToast.promise(
        updateSubmissionInfo({
          submissionId: movedToSubmissionId,
          eta: newDate,
        }),
        {
          loading: 'Updating post end date...',
          success: () => {
            mutateSingleSubmission()
            return `Changed ETA to ${movedToRoadmap?.title}`
          },
          error: () => 'Failed to update submission end date',
        }
      )
    }
  }

  const {
    submissionResults,
    mutateSubmissions,
    size,
    setSize,
    totalSubmissionResults,
    rawSubmissionData,
    submissionLoading,
  } = useSubmissionsWithFiltering(generateRoadmapFilter(filters), org, 1)

  const [remutationQueue, setRemutationQueue] = useAtom(roadmapRemutationQueueAtom)

  const remutationQueueWrapper = (mutations: any[]) => {
    // Early return if no mutations
    if (!mutations.length) return

    // Process active item mutations in a single pass
    const activeItemId = activeItem?._id
    if (!activeItemId) return

    let hasActiveItemMutations = false
    let newSubmissionResults = submissionResults || []
    let queueUpdates = []
    let changeCount = 0

    for (const mutation of mutations) {
      if (mutation.roadmapColumnId === activeItemId) {
        hasActiveItemMutations = true
        newSubmissionResults = mutation.modifyResults(newSubmissionResults)
        changeCount += mutation.changeCount
      } else {
        queueUpdates.push(mutation)
      }
    }

    // Batch updates
    if (hasActiveItemMutations) {
      performSubmissionMutation(
        mutateSubmissions,
        () => newSubmissionResults,
        rawSubmissionData,
        changeCount
      )
    }

    if (queueUpdates.length) {
      setRemutationQueue((queue) => [...queue, ...queueUpdates])
    }
  }

  useEffect(() => {
    if (isProcessingRef.current) return

    try {
      isProcessingRef.current = true
      const neededMutations = remutationQueue.filter(
        (mutation) => mutation.roadmapColumnId === activeItem?._id
      )

      if (neededMutations.length === 0) {
        isProcessingRef.current = false
        return
      }

      if (neededMutations.some((mutation) => mutation.fullRefresh)) {
        mutateSubmissions()
      } else {
        let newSubmissionResults = submissionResults || []
        let changeCount = 0

        neededMutations.forEach((mutation) => {
          newSubmissionResults = mutation.modifyResults(newSubmissionResults)
          changeCount += mutation.changeCount
        })

        performSubmissionMutation(
          mutateSubmissions,
          () => newSubmissionResults,
          rawSubmissionData,
          changeCount
        )
      }

      const finishedMutationIds = neededMutations.map((mutation) => mutation.id)

      setRemutationQueue((prevQueue) =>
        prevQueue.filter((mutation) => !finishedMutationIds.includes(mutation.id))
      )
    } catch (error) {
      console.error('Error processing mutations:', error)
    } finally {
      isProcessingRef.current = false
    }
  }, [remutationQueue, submissionResults])

  let showLoader = totalSubmissionResults ? size * 10 < totalSubmissionResults : false

  const submissionInActiveItem =
    activeDraggingItemId && activeDraggingItemId?.split('-')[0] === activeItem?._id
      ? submissionResults?.find(
          (submission) => submission.id === activeDraggingItemId?.split('-')[1]
        )
      : undefined

  useEffect(() => {
    if (submissionInActiveItem) {
      setActiveDraggingItem(
        <RoadmapElement
          roadmapItem={activeItem}
          entry={submissionInActiveItem}
          mutateSubmissions={wrapperMutateSubmissions}
          rawSubmissionData={rawSubmissionData}
          setActiveSubmissionId={setActiveSubmissionId}
          setMainPostView={setMainPostView}
        />
      )
    }
  }, [activeDraggingItemId, setActiveDraggingItem, submissionInActiveItem, activeItem?._id])

  const getStyles = (status: any) => {
    return statusColorData.find((color) => color?.name === status?.color)?.color
  }

  const wrapperMutateSubmissions = useCallback(
    async (...args: any[]) => {
      const result = await mutateSubmissions(...args)
      if (mutateSingleSubmission && result && singleSubmission) {
        let updatedSubmission: any = null
        result?.forEach((res) => {
          res?.results?.forEach((submission: ISubmission) => {
            if (submission.id === activeSubmissionId) {
              updatedSubmission = submission
            }
          })
        })
        if (updatedSubmission) {
          mutateSingleSubmission(
            {
              results: [updatedSubmission],
              page: 1,
              limit: 10,
              totalPages: 1,
              totalResults: 1,
            },
            false
          )

          setRemutationQueue(
            activeRoadmap?.items?.map((item) => ({
              id: uuid(),
              roadmapColumnId: item._id,
              changeCount: 0,
              modifyResults: (submissions: ISubmission[]) =>
                submissions.map((submission) =>
                  submission.id === activeSubmissionId ? updatedSubmission : submission
                ),
            })) || []
          )
        }
      }
      return result
    },
    [
      mutateSubmissions,
      mutateSingleSubmission,
      submissionResults,
      activeSubmissionId,
      rawSubmissionData,
    ]
  )

  const iconColor = getColor(activeItem?.color || 'Sky')
  const activeItemStyles = statusColorData?.find((s) => s.name === (activeItem?.color || 'Sky'))

  const roadmapFilters = useMemo(() => {
    return mongooseQueryStringToObject(activeItem?.filter || '')
  }, [activeItem?.filter])

  const baseRoadmapFilters = useMemo(() => {
    return mongooseQueryStringToObject(activeRoadmap?.baseFilter || '')
  }, [activeRoadmap?.baseFilter])

  // Check if moved roadmap has one status filter
  const getIdFromFilter = (filterType: string, rootRoadmapFilters?: boolean) => {
    return rootRoadmapFilters
      ? baseRoadmapFilters?.find((filter) => filter.type === filterType)?.values?.[0]
      : roadmapFilters?.find((filter) => filter.type === filterType)?.values?.[0]
  }

  const activeItemEta = !isNaN(Number(activeItem?._id))
    ? (() => {
        const timestamp = Number(activeItem._id)
        const date = new Date(timestamp)
        const year = date.getUTCFullYear()
        const month = date.getUTCMonth()
        const day = date.getUTCDate()
        return new Date(Date.UTC(year, month, day))
      })()
    : undefined

  const activeItemStatus =
    org?.postStatuses?.find((status) => status.id === getIdFromFilter('s')) ||
    org?.postStatuses?.find((status) => status.id === getIdFromFilter('s', true))

  const activeItemCategory =
    org?.postCategories?.find((category) => category.id === getIdFromFilter('b')) ||
    org?.postCategories?.find((category) => category.id === getIdFromFilter('b', true))

  return (
    <>
      <PopupWrapper
        hasPadding={false}
        fixToTop={true}
        isOpen={createPost}
        setIsOpen={setCreatePost}
        medium={true}
      >
        <CreatePost
          eta={activeItemEta}
          isOpen={createPost}
          initialCategory={activeItemCategory?.category}
          postStatus={activeItemStatus}
          mutateSubmissions={wrapperMutateSubmissions}
          setIsOpen={setCreatePost}
          rawSubmissionData={rawSubmissionData}
        />
      </PopupWrapper>
      {customModal}

      <div
        ref={setNodeRef}
        className={`relative pb-4 ${!isLast && 'md:mr-4 md:mb-0 mb-3'}  w-full ${
          moreThanTwo ? 'md:min-w-[328px]' : ''
        } md:flex-shrink-0 md:flex-1 h-full basis-full border-gray-100/50 dark:border-border/60`}
      >
        <div className="absolute top-0.5 bottom-0 right-0 -left-1.5 bg-gray-50/[70%] dark:bg-darker-bg/[13%] rounded-lg"></div>
        <div className="sticky top-0 z-10 flex items-center justify-between py-3 mx-1 backdrop-filter">
          <p
            className={cn(
              `font-semibold pointer-events-none max-w-[265px] px-2 py-1 rounded-md border flex text-xs items-center`,
              activeItemStyles?.color
            )}
          >
            <span className="truncate first-letter:uppercase inline-flex items-center">
              {activeItem?.icon && (
                <span style={{ color: iconColor }} className="mr-1.5 opacity-90 dark:opacity-80">
                  <FeaturedIcon small={true} icon={activeItem?.icon} />
                </span>
              )}
              {activeItem?.title}
            </span>
          </p>
          <div className="flex items-center gap-2 mr-1">
            {totalSubmissionResults ? (
              <code className="text-xs !font-semibold bg-white border border-gray-100/50 dark:border-border/60 dark:bg-secondary/60 text-foreground/80 dark:text-foreground/60">
                {shortenNumber(totalSubmissionResults || 0)}
              </code>
            ) : null}
            {isMember(user?.id, org) && (
              <Button
                onClick={() => setCreatePost(true)}
                variant="outline"
                className="shadow-none px-1 py-[3px] dark:bg-secondary/60 dark:border-border/60 rounded-md h-full w-full"
              >
                <PlusIcon className="w-3.5 h-3.5 secondary-svg" />
              </Button>
            )}
          </div>
          {/* Additional logic for action buttons */}
        </div>
        <div className="relative">
          <AnimatePresence>
            {isOver && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.35,
                  ease: 'easeInOut',
                }}
                className="absolute -ml-1.5 inset-0 -mt-1 -mb-4 rounded-md z-50 flex flex-col p-4 backdrop-blur-[1px] items-center justify-center bg-white/50 border dark:border-secondary dark:bg-secondary/50"
              >
                <div className="flex flex-col items-center justify-center p-4 bg-white border rounded-md shadow-sm dark:bg-secondary dark:border-border dark:shadow">
                  <p className="text-sm font-semibold text-gray-600 dark:text-gray-50">
                    Drop here to move to this column
                  </p>
                  <p className="mt-2 text-xs text-gray-400 dark:text-foreground">
                    This list is ordered by{' '}
                    {filters.sortBy && sortByNames[filters.sortBy ? filters.sortBy : '']}
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="">
            <AnimatePresence>
              <motion.div
                className={cn(
                  'relative custom-scrollbar',
                  activeDraggingItemId ? 'overflow-hidden' : ' sm:overflow-y-auto',
                  !isFullRoadmap ? 'sm:h-[60vh]' : 'sm:h-[82vh]'
                )}
              >
                {can(user?.id, 'set_post_status', org) ? (
                  <AnimatePresence initial={false}>
                    {submissionResults?.map((entry, index) => (
                      <DraggableElement
                        entry={entry}
                        id={activeItem?._id + '-' + entry.id}
                        key={entry.id}
                        org={org}
                        user={user}
                        width={width}
                        index={index}
                      >
                        <RoadmapElement
                          roadmapItem={activeItem}
                          entry={entry}
                          enableRedirection={enableRedirection}
                          mutateSubmissions={wrapperMutateSubmissions}
                          rawSubmissionData={rawSubmissionData}
                          setActiveSubmissionId={setActiveSubmissionId}
                          setMainPostView={setMainPostView}
                          setUrl={setUrl}
                        />
                      </DraggableElement>
                    ))}
                  </AnimatePresence>
                ) : (
                  submissionResults?.map((entry, index) => (
                    <RoadmapElement
                      entry={entry}
                      roadmapItem={activeItem}
                      enableRedirection={enableRedirection}
                      mutateSubmissions={wrapperMutateSubmissions}
                      rawSubmissionData={rawSubmissionData}
                      setActiveSubmissionId={setActiveSubmissionId}
                      setMainPostView={setMainPostView}
                      key={entry.id}
                      setUrl={setUrl}
                    />
                  ))
                )}

                <div className="mt-5 sm:mt-8">
                  {submissionLoading && (
                    <div className="pb-7">
                      <div className="w-6 h-6 mx-auto secondary-svg">
                        <Loader />
                      </div>
                    </div>
                  )}
                  <div className="sm:hidden">
                    {!submissionLoading && showLoader && (
                      <div className="px-1 -mt-3">
                        <button
                          className="dashboard-secondary"
                          onClick={() => {
                            setSize(size + 1)
                          }}
                        >
                          Load more
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="hidden sm:block">
                    {!submissionLoading && showLoader && !isLoading && (
                      <div>
                        <InView
                          as="div"
                          onChange={(inView: boolean) => {
                            inView && setSize(size + 1)
                          }}
                        >
                          <div className="flex items-center justify-center mt-4 text-gray-200 dark:text-foreground/60 pb-7">
                            <div className="w-6 h-6 secondary-svg">
                              <Loader />
                            </div>
                          </div>
                        </InView>
                      </div>
                    )}
                  </div>
                  {submissionResults?.length === 0 && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="flex flex-col items-center justify-center pt-6 pb-4 text-sm font-medium text-center text-background-accent sm:pt-20 sm:pb-10 dark:text-foreground"
                    >
                      <div className="w-16 h-16 mx-auto mb-4">
                        <EmptyIllustration tailwind={getStyles(activeItem)} />
                      </div>
                      <p>
                        {' '}
                        {!activeRoadmap?.template?.type ? (
                          <Trans
                            i18nKey="no-status-posts"
                            components={[<span className="capitalize" key="nostatusposts" />]}
                            values={{ status: activeItem?.title }}
                          ></Trans>
                        ) : (
                          <Trans
                            i18nKey="nothing-scheduled-for-quarter"
                            components={[<span className="capitalize" key="noquarterposts" />]}
                            values={{ date: activeItem?.title }}
                          ></Trans>
                        )}
                      </p>
                    </motion.div>
                  )}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  )
}

export default DedicatedRoadmapField
